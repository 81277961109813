import React, { useState } from 'react';
import { useSelector } from 'react-redux';


import FundsProof from './fundsProof';
import IdProof from './idProof.js';


// import lib
import { dateTimeFormat } from '../../lib/dateTimeHelper';

//import modal
const initialFormValue = {
    type: "driving_license",
    proofNumber: "",
    frontImage: "",
    backImage: "",
    selfiImage: "",
};
const AccountSettings = () => {



    return (
        <div className="dash_wrapper">
            <div className="row">
                <div className="col-lg-8">
                    <div className="dash_box">
                        <IdProof />
                    </div>
                    <div className="dash_box">
                        <FundsProof />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AccountSettings;